<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <flixCheckPermissions role="calendar" permission="2" error>
            <publishCalendar />
          </flixCheckPermissions>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>
<script>
export default {
  components: {
    publishCalendar () { return import('@/components/publish/') }
  },
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
